import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
  dividerTitle: {
    backgroundColor: '#0089a1',
    width: '33%',
    marginBottom: '0px',
    height: '3px',
    margin: '20px auto'
  },
  bold: {
    fontWeight: 700
  },
  url: {
    color: '#7c7c7c',
    textDecoration: 'none',
    '&:hover': {
      color: '#1f2532',
      textDecoration: 'none'
    }
  },
  link: {
    backgroundColor: '#0089a1',
    marginBottom: '1rem',
    color: '#ffffff',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8
    }
  }
}));

const About = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box>
        {/*<ImageContainer aspectRatio={(16/6)} height='100%' src={contactUsImage} alt='Contact Us' />*/}
      </Box>
      <div className={classes.content}>
        <Typography gutterBottom variant="h1" align="center" color="textPrimary" fontWeight="500" style={{ fontSize: '30px', textTransform: 'none' }} >
          About Us
        </Typography>
        <Divider className={classes.dividerTitle} />
        <Grid item xs={12} md={2}></Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={12}>
            <p style={{ textTransform: 'none',textAlign:'left', fontSize:'1rem'}}  >
              At AksonsiCar, we are committed to providing a wide choice of quality pre-owned cars complete with excellent service. The South African banks certifies our brands because our vehicles undergo strict quality checks before arriving on our dealership floors. This means you can buy with confidence at AksonsiCar, where second-hand will never feel second best.
              <br /><br />
              We can also provide you finance, insurance and value-added products like scratch and dent cover and warranty. We're here to help make your car buying experience efficient and stress free.
              <br />
              <br />
              We make our customers feel like family.
            </p>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default About;